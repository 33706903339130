import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthService } from 'src/app/services/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { ActionType, AppConstants } from 'src/app/shared/AppConstants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
  MatTreeNestedDataSource,
} from '@angular/material/tree';
import { FlatTreeControl, NestedTreeControl } from '@angular/cdk/tree';
import { Router } from '@angular/router';
import { ErrorAlertComponent } from 'src/app/components/error alert/error-alert.component';
import { LocationService } from 'src/app/services/location.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { AddLocationCategoryComponent } from 'src/app/components/add-location-category/add-location-category.component';
import { AddSubLocationComponent } from 'src/app/components/add-sub-location/add-sub-location.component';
import { ExtensionFieldsComponent } from 'src/app/components/extension-fields/extension-fields.component';
import { AddEquipmentsComponent } from 'src/app/components/add-equipments/add-equipments.component';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CustomerSelectComponent } from 'src/app/components/customer-select/customer-select.component';

interface Location {
  name: string;
  id: any;
  categoryId: any,
  childrens: Location[];
}

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  id: any;
  categoryId: any,
  level: number;
}

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
})
export class LocationsComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  @ViewChild(MatPaginator, { static: false }) paginator!: any;
  @ViewChild(MatSort, { static: false }) sort!: any;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  public version: string;
  public release: string;
  public constants: AppConstants;
  public locationFormGroup: FormGroup = this._formBuilder.group({});
  public locationDetailsFormGroup: FormGroup = this._formBuilder.group({});
  public isShowProgressBar: boolean = false;
  public locationCategories: any[] = [];
  public locationEquipments: any[] = [];
  public locationId: string = '';
  public name: string = '';
  public categoryId: string = '';
  public isShowSaveLocProgressBar: boolean = false;
  public errorMsg: string = '';
  public locationDetails: any;
  public isShowlocationDetailsProgressBar: boolean = false;
  public selectedIndex: number = 0;
  public equipmentDataSource: MatTableDataSource<any>;
  public paginatorLength: number;
  public equipmentsLength: number = 0;
  public selectedFilter: any = '';
  public tableColumns: string[] = [
    'EQUIPMENT_ID',
    'NAME',
    'BOM_ID',
    'TAG',
    'CATEGORY_NAME',
    'LOCATION',
    // 'DESCRIPTION',
    'Actions',
  ];
  public selectedCategory : any;
  public equipmentCategories: any[] = [];

  private transformer = (node: Location, level: number) => {
    return {
      expandable: !!node.childrens && node.childrens.length > 0,
      name: node.name,
      categoryId: node.categoryId,
      id: node.id,
      level: level,
    };
  };

  public treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.childrens
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  public isAirlineCusromer: boolean = false;
  public appropriateClass:string = '';
  public events: string[] = [];
  
  @HostListener('window:resize', ['$event'])
  getScreenHeight(event?: any){
    if(window.innerHeight<=700){
      this.appropriateClass = 'bottomRelative';
    }else{
      this.appropriateClass = 'bottomStick';
    }
  }
  constructor(
    public dialog: NgDialogAnimationService,
    public authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    public utilService: UtilService,
    private _formBuilder: FormBuilder,
    public router: Router,
    public locationService: LocationService
  ) {
    this.getScreenHeight();
    this.constants = new AppConstants();
    this.paginatorLength = this.constants.INITIAL_PAGINATOR_LIMIT;
    this.version = this.constants.APP_RELEASE_NUMBER;
    this.release = this.constants.APP_RELEASE_DATE;
    this.equipmentDataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    let temp: any = localStorage.getItem('user-details');
    let user: any = JSON.parse(temp);
    this.isAirlineCusromer = user.CUSTOMER_TYPE == 'AIRLINE' ? true : false;
    let customerId: any = localStorage.getItem('customerId');
    if (customerId && customerId != null) {
      if (customerId == 1) {
        this.authService.isAFSUser = true;
      } else {
        this.authService.isAFSUser = false;
      }
    }
    this.locationFormGroup = this._formBuilder.group({
      locCtrl: ['', Validators.required],
    });

    this.locationFormGroup.controls.locCtrl.valueChanges.subscribe((value) => {
      if (value) {
        this.locationDetailsFormGroup.disable();
        this.locationDetailsFormGroup.controls['locationId'].disable();
      }
    });

    this.locationDetailsFormGroup = this._formBuilder.group({
      locationId: [{ value: '', disabled: true }, Validators.required],
      name: [{ value: '', disabled: true }, Validators.required],
      description: [{ value: '', disabled: true }],
      category: [{ value: '', disabled: true }, Validators.required],
      latitude: [{ value: '', disabled: true }],
      longitude: [{ value: '', disabled: true }],
    });
    this.getLocations();
    this.getLocationCategories();
    this.getEquipmentCategories();
  }

  refreshData() {
    this.selectedIndex = 0;
    this.locationFormGroup.controls.locCtrl.reset();
    this.locationDetailsFormGroup.reset();
    this.locationDetailsFormGroup.markAsUntouched({
      onlySelf: true,
    });
    this.locationDetailsFormGroup.disable();
    this.getLocations();
    this.getLocationCategories();
    this.getEquipmentCategories();
    this.locationDetailsFormGroup.controls['locationId'].disable();
    this.locationEquipments = [];
    this.equipmentDataSource = new MatTableDataSource();
    this.equipmentsLength = 0;
    this.selectedFilter = '';
    this.selectedCategory = '';
  }

  // public hasChild = (_: number, node: Location) =>
  //   !!node.childrens && node.childrens.length > 0;

  logout() {
    this.authService.logout();
  }

  // Get Locations
  getLocations(location?: any) {
    this.isShowProgressBar = true;
    this.locationService.getLocations().subscribe(
      (response: any) => {
        // Success callback
        this.isShowProgressBar = false;
        if (response.status === 200) {
          if (response?.body && response?.body?.length > 0) {
            // Assign the data
            this.dataSource.data = response.body;
            if (location) {
              let temp = location.split('-');
              if (temp && temp.length > 0) {
                let locationArr: any = [];
                for (let l = 0; l < temp.length; l++) {
                  if (l == 0) {
                    locationArr.push(temp[l]);
                  } else {
                    locationArr.push(locationArr[l - 1] + '-' + temp[l]);
                  }
                }

                if (locationArr && locationArr.length > 0) {
                  let that = this;
                  for (let i = 0; i < locationArr.length; i++) {
                    this.treeControl?.dataNodes?.filter(function (e) {
                      if (e.id == locationArr[i]) {
                        that.treeControl?.expand(e);
                      }
                    });
                  }
                }
              }
            }
          } else {
            this.dataSource.data = [];
          }
        } else if (response.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (response.status === 403) {
          this.showErrorAlertDialog(response.message);
        } else {
          this.showErrorAlertDialog(response.message);
        }
      },
      (error) => {
        this.isShowProgressBar = false;
        if (error.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (error.status === 403) {
          this.showErrorAlertDialog(error.error);
        } else {
          this.showErrorAlertDialog(error.message);
        }
      }
    );
  }

  // Error dialog when any server erros
  showErrorAlertDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  addSublocation(location: any) {
    const dialogRef = this.dialog.open(AddSubLocationComponent, {
      width: '550px',
      panelClass: 'modify-customer-dialog',
      data: {
        mode: 'sub',
        id: location?.id,
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.getLocations(result);
      }
    });
  }

  async addLocation() {
    const dialogRef = this.dialog.open(AddSubLocationComponent, {
      width: '550px',
      panelClass: 'modify-customer-dialog',
      data: {
        mode: '',
        id: '',
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.getLocations();
      }
    });
  }

  getLocationDetails(id: any) {
    if (id) {
      this.locationEquipments = [];
      this.selectedFilter = '';
      this.selectedCategory = '';
      this.isShowlocationDetailsProgressBar = true;
      this.locationService.getLocationById(id).subscribe(
        (response: any) => {
          this.isShowlocationDetailsProgressBar = false;
          // Success callback
          if (response.status === 200) {
            if (response.body) {
              this.locationDetails = response.body;
              if (this.locationDetails) {
                this.locationDetailsFormGroup.controls.locationId.setValue(
                  this.locationDetails.LOCATION_ID
                );
                this.locationDetailsFormGroup.controls.name.setValue(
                  this.locationDetails.NAME
                );
                this.locationDetailsFormGroup.controls.description.setValue(
                  this.locationDetails.DESCRIPTION
                );
                this.locationDetailsFormGroup.controls.category.setValue(
                  this.locationDetails.CATEGORY_ID
                );
                this.locationDetailsFormGroup.controls.latitude.setValue(
                  this.locationDetails.LATITUDE
                );
                this.locationDetailsFormGroup.controls.longitude.setValue(
                  this.locationDetails.LONGITUDE
                );
              }
            }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.showErrorAlertDialog(response.message);
          } else {
            this.showErrorAlertDialog(response.message);
          }
        },
        (error) => {
          this.isShowlocationDetailsProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.showErrorAlertDialog(error.error);
          } else {
            this.showErrorAlertDialog(error.error);
          }
        }
      );

      this.isShowlocationDetailsProgressBar = true;
      this.locationService
        .getEquipmentsByLocationId(id, '', this.paginatorLength, 0, '', '', '')
        .subscribe(
          (response: any) => {
            this.isShowlocationDetailsProgressBar = false;
            // Success callback
            if (response.status === 200) {
              if (response.body && response?.body?.EQUIPMENTS?.length > 0) {
                this.equipmentDataSource = new MatTableDataSource(
                  response.body.EQUIPMENTS
                );
                this.locationEquipments = response.body.EQUIPMENTS;
                this.equipmentsLength = response.body.EQUIPMENTS_COUNT;
              } else {
                this.locationEquipments = [];
                this.equipmentDataSource = new MatTableDataSource();
                this.equipmentsLength = 0;
              }
            } else if (response.status === 401) {
              // Redirect to login for unauthorized
              localStorage.removeItem('token');
              localStorage.removeItem('user-details');
              localStorage.removeItem('customerName');
              localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');
              this.authService.loggedIn.next(false);
              this.router.navigate(['login']);
            } else if (response.status === 403) {
              this.showErrorAlertDialog(response.message);
            } else {
              this.showErrorAlertDialog(response.message);
            }
          },
          (error) => {
            this.isShowlocationDetailsProgressBar = false;
            if (error.status === 401) {
              // Redirect to login for unauthorized
              localStorage.removeItem('token');
              localStorage.removeItem('user-details');
              localStorage.removeItem('customerName');
              localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');
              this.authService.loggedIn.next(false);
              this.router.navigate(['login']);
            } else if (error.status === 403) {
              this.showErrorAlertDialog(error.error);
            } else {
              this.showErrorAlertDialog(error.error);
            }
          }
        );
    }
  }

  editLocationDetails() {
    this.locationDetailsFormGroup.markAsUntouched({
      onlySelf: true,
    });
    this.locationDetailsFormGroup.enable();
    this.locationDetailsFormGroup.controls['locationId'].disable();
  }

  cancelEditLocationDetails() {
    this.locationDetailsFormGroup.disable();
    this.locationDetailsFormGroup.controls['locationId'].disable();
  }

  getLocationCategories() {
    // this.isShowProgressBar = true;
    this.locationCategories = [];
    this.locationService.getLocationCategories().subscribe(
      (response: any) => {
        // Success callback
        // this.isShowProgressBar = false;
        if (response.status === 200) {
          if (response.body) {
            // Assign the data
            this.locationCategories = response.body;
          }
        } else if (response.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (response.status === 403) {
          this.errorMsg = response.message;
        } else {
          this.errorMsg = response.message;
        }
      },
      (error) => {
        // this.isShowProgressBar = false;
        if (error.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (error.status === 403) {
          this.errorMsg = error.error;
        } else {
          this.errorMsg = error.error;
        }
      }
    );
  }

  async updateLocationDetails() {
    // this.isShowProgressBar = true;
    let body = {
      LOCATION_ID: this.locationDetailsFormGroup.controls.locationId.value,
      NAME: this.locationDetailsFormGroup.controls.name.value,
      CATEGORY_ID: this.locationDetailsFormGroup.controls.category.value,
      DESCRIPTION: this.locationDetailsFormGroup.controls.description.value,
      LATITUDE: this.locationDetailsFormGroup.controls.latitude.value
        ? this.locationDetailsFormGroup.controls.latitude.value
        : null,
      LONGITUDE: this.locationDetailsFormGroup.controls.longitude.value
        ? this.locationDetailsFormGroup.controls.longitude.value
        : null,
    };
    await this.locationService
      .updateLocationDetails(body)
      .toPromise()
      .then(
        (response: any) => {
          // this.isShowProgressBar = false;
          if (response.status === 204) {
            this.getLocations();
          } else {
            this.errorMsg = response.message;
          }
        },
        (error) => {
          // this.isShowProgressBar = false;
          this.errorMsg = error.error;
        }
      );
  }

  editCategory() {
    let category: any = [];
    if (this.locationCategories && this.locationCategories.length > 0) {
      let that = this;
      category = this.locationCategories.filter(function (e) {
        return (
          e.CATEGORY_ID == that.locationDetailsFormGroup.controls.category.value
        );
      });
      category = category?.length > 0 ? category[0] : {};
    }
    const dialogRef = this.dialog.open(AddLocationCategoryComponent, {
      width: '350px',
      panelClass: 'modify-customer-dialog',
      animation: { to: 'left' },
      position: { right: '0' },
      data: {
        mode: ActionType.Edit,
        item: category,
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.getLocationCategories();
      }
    });
  }

  addCategory() {
    const dialogRef = this.dialog.open(AddLocationCategoryComponent, {
      width: '350px',
      panelClass: 'modify-customer-dialog',
      animation: { to: 'left' },
      position: { right: '0' },
      data: { mode: ActionType.Add, item: {} },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.getLocationCategories();
        this.locationDetailsFormGroup.controls.category.setValue(result);
      }
    });
  }

  showExtensionFields(item: any) {
    const dialogRef = this.dialog.open(ExtensionFieldsComponent, {
      width: '650px',
      height: '420px',
      panelClass: 'modify-customer-dialog',
      data: { item: item },
    });
  }

  tabChanged(ev: any) {
    this.selectedIndex = ev.index;
  }

  addEquipment() {
    const dialogRef = this.dialog.open(AddEquipmentsComponent, {
      width: '390px',
      panelClass: 'add-customer-dialog',
      animation: { to: 'left' },
      position: { right: '0' },
      data: {
        mode: 1,
        item: {
          locationId:
            this.locationDetailsFormGroup.controls['locationId'].value,
        },
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.isShowlocationDetailsProgressBar = true;
        this.locationService
          .getEquipmentsByLocationId(
            this.locationDetailsFormGroup.controls['locationId'].value,
            '',
            this.paginatorLength,
            0,
            '',
            '',
            ''
          )
          .subscribe(
            (response: any) => {
              this.isShowlocationDetailsProgressBar = false;
              // Success callback
              if (response.status === 200) {
                if (response.body && response?.body?.EQUIPMENTS?.length > 0) {
                  this.equipmentDataSource = new MatTableDataSource(
                    response.body.EQUIPMENTS
                  );
                  this.locationEquipments = response.body.EQUIPMENTS;
                  this.equipmentsLength = response.body.EQUIPMENTS_COUNT;
                } else {
                  this.locationEquipments = [];
                  this.equipmentDataSource = new MatTableDataSource();
                  this.equipmentsLength = 0;
                }
              } else if (response.status === 401) {
                // Redirect to login for unauthorized
                localStorage.removeItem('token');
                localStorage.removeItem('user-details');
                localStorage.removeItem('customerName');
                localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');
                this.authService.loggedIn.next(false);
                this.router.navigate(['login']);
              } else if (response.status === 403) {
                this.showErrorAlertDialog(response.message);
              } else {
                this.showErrorAlertDialog(response.message);
              }
            },
            (error) => {
              this.isShowlocationDetailsProgressBar = false;
              if (error.status === 401) {
                // Redirect to login for unauthorized
                localStorage.removeItem('token');
                localStorage.removeItem('user-details');
                localStorage.removeItem('customerName');
                localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');
                this.authService.loggedIn.next(false);
                this.router.navigate(['login']);
              } else if (error.status === 403) {
                this.showErrorAlertDialog(error.error);
              } else {
                this.showErrorAlertDialog(error.error);
              }
            }
          );
      }
    });
  }

  editEquipment(item: any) {
    this.locationService.extensionFields = item?.EXTN;
    const dialogRef = this.dialog.open(AddEquipmentsComponent, {
      width: '390px',
      panelClass: 'add-customer-dialog',
      animation: { to: 'left' },
      position: { right: '0' },
      data: {
        mode: 0,
        item: item,
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      this.locationService.extensionFields = null;
      if (result) {
        this.isShowlocationDetailsProgressBar = true;
        this.locationService
          .getEquipmentsByLocationId(
            this.locationDetailsFormGroup.controls['locationId'].value,
            '',
            this.paginatorLength,
            0,
            '',
            '',
            ''
          )
          .subscribe(
            (response: any) => {
              this.isShowlocationDetailsProgressBar = false;
              // Success callback
              if (response.status === 200) {
                if (response.body && response?.body?.EQUIPMENTS?.length > 0) {
                  this.equipmentDataSource = new MatTableDataSource(
                    response.body.EQUIPMENTS
                  );
                  this.locationEquipments = response.body.EQUIPMENTS;
                  this.equipmentsLength = response.body.EQUIPMENTS_COUNT;
                } else {
                  this.locationEquipments = [];
                  this.equipmentDataSource = new MatTableDataSource();
                  this.equipmentsLength = 0;
                }
              } else if (response.status === 401) {
                // Redirect to login for unauthorized
                localStorage.removeItem('token');
                localStorage.removeItem('user-details');
                localStorage.removeItem('customerName');
                localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');
                this.authService.loggedIn.next(false);
                this.router.navigate(['login']);
              } else if (response.status === 403) {
                this.showErrorAlertDialog(response.message);
              } else {
                this.showErrorAlertDialog(response.message);
              }
            },
            (error) => {
              this.isShowlocationDetailsProgressBar = false;
              if (error.status === 401) {
                // Redirect to login for unauthorized
                localStorage.removeItem('token');
                localStorage.removeItem('user-details');
                localStorage.removeItem('customerName');
                localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');
                this.authService.loggedIn.next(false);
                this.router.navigate(['login']);
              } else if (error.status === 403) {
                this.showErrorAlertDialog(error.error);
              } else {
                this.showErrorAlertDialog(error.error);
              }
            }
          );
      }
    });
  }

  activatAndDeavtivateEquipment(equipment: any, type: any) {
    let msg = '';
    if (type == 'Y') {
      msg = 'Are you sure you want to deactivate this equipment?';
    } else {
      msg = 'Are you sure you want to activate this equipment?';
    }
    const dialogRef = this.dialog.open(AlertComponent, {
      width: '400px',
      data: { errorMsg: msg },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.data) {
        let body = {
          EQUIPMENT_ID: equipment.EQUIPMENT_ID,
          INACTIVE: type,
        };

        await this.locationService
          .updateEquipmentByLocation(body)
          .toPromise()
          .then(
            (response: any) => {
              this.isShowProgressBar = false;
              if (response.status === 204) {
                this.isShowlocationDetailsProgressBar = true;
                this.locationService
                  .getEquipmentsByLocationId(
                    this.locationDetailsFormGroup.controls['locationId'].value,
                    '',
                    this.paginatorLength,
                    0,
                    '',
                    '',
                    ''
                  )
                  .subscribe(
                    (response: any) => {
                      this.isShowlocationDetailsProgressBar = false;
                      // Success callback
                      if (response.status === 200) {
                        if (
                          response.body &&
                          response?.body?.EQUIPMENTS?.length > 0
                        ) {
                          this.equipmentDataSource = new MatTableDataSource(
                            response.body.EQUIPMENTS
                          );
                          this.locationEquipments = response.body.EQUIPMENTS;
                          this.equipmentsLength =
                            response.body.EQUIPMENTS_COUNT;
                        } else {
                          this.locationEquipments = [];
                          this.equipmentDataSource = new MatTableDataSource();
                          this.equipmentsLength = 0;
                        }
                      } else if (response.status === 401) {
                        // Redirect to login for unauthorized
                        localStorage.removeItem('token');
                        localStorage.removeItem('user-details');
                        localStorage.removeItem('customerName');
                        localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');
                        this.authService.loggedIn.next(false);
                        this.router.navigate(['login']);
                      } else if (response.status === 403) {
                        this.showErrorAlertDialog(response.message);
                      } else {
                        this.showErrorAlertDialog(response.message);
                      }
                    },
                    (error) => {
                      this.isShowlocationDetailsProgressBar = false;
                      if (error.status === 401) {
                        // Redirect to login for unauthorized
                        localStorage.removeItem('token');
                        localStorage.removeItem('user-details');
                        localStorage.removeItem('customerName');
                        localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');
                        this.authService.loggedIn.next(false);
                        this.router.navigate(['login']);
                      } else if (error.status === 403) {
                        this.showErrorAlertDialog(error.error);
                      } else {
                        this.showErrorAlertDialog(error.error);
                      }
                    }
                  );
              } else {
                this.errorMsg = response.message;
              }
            },
            (error) => {
              this.isShowProgressBar = false;
              this.errorMsg = error.error;
            }
          );
      }
    });
  }

  async filterEquipments(type: any) {
    this.selectedFilter = type;
    this.isShowlocationDetailsProgressBar = true;
    this.locationService
      .getEquipmentsByLocationId(
        this.locationDetailsFormGroup.controls['locationId'].value,
        type,
        this.paginatorLength,
        0,
        '',
        '',
        '',
        this.selectedCategory
      )
      .subscribe(
        (response: any) => {
          this.isShowlocationDetailsProgressBar = false;
          // Success callback
          if (response.status === 200) {
            if (response.body && response?.body?.EQUIPMENTS?.length > 0) {
              this.equipmentDataSource = new MatTableDataSource(
                response.body.EQUIPMENTS
              );
              this.locationEquipments = response.body.EQUIPMENTS;
              this.equipmentsLength = response.body.EQUIPMENTS_COUNT;
            } else {
              this.locationEquipments = [];
              this.equipmentDataSource = new MatTableDataSource();
              this.equipmentsLength = 0;
            }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.showErrorAlertDialog(response.message);
          } else {
            this.showErrorAlertDialog(response.message);
          }
        },
        (error) => {
          this.isShowlocationDetailsProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.showErrorAlertDialog(error.error);
          } else {
            this.showErrorAlertDialog(error.error);
          }
        }
      );
  }

  handlePaginator(e: any) {
    this.paginatorLength = e.pageSize;
    let limit = e.pageSize;
    let offset = e.pageIndex * limit;
    this.paginator.pageIndex = e.pageIndex;
    if (this.sort.active) {
      this.getEquipments(
        limit,
        offset,
        '',
        this.sort.active,
        this.sort._direction
      );
    } else {
      this.getEquipments(limit, offset, '', '', '');
    }
  }

  sortfields(e: any) {
    let offset = this.paginator._pageIndex * this.paginator._pageSize;
    this.getEquipments(this.paginatorLength, offset, '', e.active, e.direction);
    this.paginator.pageIndex = this.paginator._pageIndex;
  }

  getEquipments(
    limit?: number,
    offset?: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any
  ) {
    this.isShowlocationDetailsProgressBar = true;
    this.locationService
      .getEquipmentsByLocationId(
        this.locationDetailsFormGroup.controls['locationId'].value,
        this.selectedFilter,
        limit,
        offset,
        searchText,
        sortField,
        sortDirection
      )
      .subscribe(
        (response: any) => {
          this.isShowlocationDetailsProgressBar = false;
          // Success callback
          if (response.status === 200) {
            if (response.body && response?.body?.EQUIPMENTS?.length > 0) {
              this.equipmentDataSource = new MatTableDataSource(
                response.body.EQUIPMENTS
              );
              this.locationEquipments = response.body.EQUIPMENTS;
              this.equipmentsLength = response.body.EQUIPMENTS_COUNT;
            } else {
              this.locationEquipments = [];
              this.equipmentDataSource = new MatTableDataSource();
              this.equipmentsLength = 0;
            }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.showErrorAlertDialog(response.message);
          } else {
            this.showErrorAlertDialog(response.message);
          }
        },
        (error) => {
          this.isShowlocationDetailsProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.showErrorAlertDialog(error.error);
          } else {
            this.showErrorAlertDialog(error.error);
          }
        }
      );
  }

  categoryChange(ev: any) {
    this.isShowlocationDetailsProgressBar = true;
    this.locationService
      .getEquipmentsByLocationId(
        this.locationDetailsFormGroup.controls['locationId'].value,
        this.selectedFilter,
        this.paginatorLength,
        0,
        '',
        '',
        '',
        this.selectedCategory
      )
      .subscribe(
        (response: any) => {
          this.isShowlocationDetailsProgressBar = false;
          // Success callback
          if (response.status === 200) {
            if (response.body && response?.body?.EQUIPMENTS?.length > 0) {
              this.equipmentDataSource = new MatTableDataSource(
                response.body.EQUIPMENTS
              );
              this.locationEquipments = response.body.EQUIPMENTS;
              this.equipmentsLength = response.body.EQUIPMENTS_COUNT;
            } else {
              this.locationEquipments = [];
              this.equipmentDataSource = new MatTableDataSource();
              this.equipmentsLength = 0;
            }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.showErrorAlertDialog(response.message);
          } else {
            this.showErrorAlertDialog(response.message);
          }
        },
        (error) => {
          this.isShowlocationDetailsProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.showErrorAlertDialog(error.error);
          } else {
            this.showErrorAlertDialog(error.error);
          }
        }
      );
  }

  getEquipmentCategories() {
    this.isShowProgressBar = true;
    this.equipmentCategories = [];
    this.locationService.getEquipmentCategories().subscribe(
      (response: any) => {
        // Success callback
        this.isShowProgressBar = false;
        if (response.status === 200) {
          if (response.body) {
            // Assign the data
            this.equipmentCategories = response.body.EQUIPMENT_CATEGORIES;
          }
        } else if (response.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (response.status === 403) {
          this.errorMsg = response.message;
        } else {
          this.errorMsg = response.message;
        }
      },
      (error) => {
        this.isShowProgressBar = false;
        if (error.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (error.status === 403) {
          this.errorMsg = error.error;
        } else {
          this.errorMsg = error.error;
        }
      }
    );
  }

  switchCompany() {
    let userTenants: any = localStorage.getItem('userTenants');
    if (userTenants && userTenants != null) {
      userTenants = JSON.parse(userTenants);
      const dialogRef = this.dialog.open(CustomerSelectComponent, {
        panelClass: 'add-customer-dialog',
        width: '550px',
        height: '380px',
        disableClose: false,
        data: { mode: 0, item: userTenants },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
        this.refreshData();
        }
      });
    }
  }
}
