<!--#region Toolbar -->
<mat-toolbar class="app-toolbar-fixed">
    <button mat-icon-button (click)="closeDialog()" onclick="this.blur()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <span>{{title}}</span>
    <span class="example-spacer"></span>
</mat-toolbar>
<mat-progress-bar *ngIf="isShowProgressBar" class="primary-color" mode="indeterminate">
</mat-progress-bar>

<div style="padding: 30px;">
    <mat-stepper linear #stepper>

        <mat-step [stepControl]="locationFormGroup" [editable]="isEditable">
            <form [formGroup]="locationFormGroup">
                <ng-template matStepLabel>Choose location</ng-template>

                <mat-radio-group formControlName="locCtrl">

                    <mat-tree style="margin-top:20px" [dataSource]="locationDataSource" [treeControl]="treeControl"
                        class="example-tree">
                        <!-- This is the tree node template for leaf nodes -->
                        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                            <li class="mat-tree-node">
                                <!-- use a disabled button to provide padding for tree leaf -->
                                <button mat-icon-button disabled></button>
                                <mat-radio-button [value]="node.id">{{node.name}}</mat-radio-button>

                            </li>
                        </mat-tree-node>
                        <!-- This is the tree node template for expandable nodes -->
                        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                            <li>
                                <div class="mat-tree-node">
                                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                                        <mat-icon class="mat-icon-rtl-mirror">
                                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                        </mat-icon>
                                    </button>
                                    <mat-radio-button [value]="node.id">{{node.name}}</mat-radio-button>

                                </div>
                                <ul [class.tree-invisible]="!treeControl.isExpanded(node)">
                                    <ng-container matTreeNodeOutlet></ng-container>
                                </ul>
                            </li>
                        </mat-nested-tree-node>
                    </mat-tree>

                </mat-radio-group>

                <mat-toolbar style="position:absolute;bottom:20px;height:40px;background:none;right: 20px;">
                    <span class="example-spacer"></span>
                    <button [disabled]="!locationFormGroup.valid" 
                    (click)="locationNext()" [ngClass]="!locationFormGroup.valid ? '' : 'primary-button'" mat-button
                        matStepperNext>Next</button>
                </mat-toolbar>

            </form>
        </mat-step>

        <mat-step [stepControl]="detailsFormGroup" [editable]="isEditable">
            <form [formGroup]="detailsFormGroup">
                <ng-template matStepLabel>Enter details</ng-template>

                <div style="padding: 2.5rem !important;margin: auto;text-align: center;width: 50%;margin-top: 20px;border:1px solid #dbdbdb"
                    class="mat-elevation-z2">

                    <div>
                        <mat-form-field appearance="outline" style="font-size: 17px;">
                            <mat-label>Selected Location</mat-label>
                            <input matInput readonly="true" formControlName="selectedLocationCtrl">
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field appearance="outline" style="font-size: 17px;">
                            <mat-label>Period</mat-label>
                            <mat-select formControlName="periodCtrl" disableOptionCentering required>
                                <mat-option *ngFor="let period of periodList" [value]="period.value">
                                    {{period.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field appearance="outline" style="font-size: 17px;" *ngIf="detailsFormGroup.controls.periodCtrl.value !== 'ADHOC'">
                            <mat-label>Service Start Date</mat-label>
                            <input matInput [matDatepicker]="picker" (click)="picker.open()" readonly required formControlName="startDate">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field appearance="outline" style="font-size: 17px;">
                            <mat-label>Service Type</mat-label>
                            <mat-select formControlName="serviceTypeCtrl" [disabled]="data.mode == 2" disableOptionCentering required>
                                <mat-option value="AUDIT">Audit</mat-option>
                                <mat-option value="TASK">Task</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field appearance="outline" style="font-size: 17px;margin-bottom: -26px;">
                            <mat-label>Description</mat-label>
                            <textarea matInput formControlName="descriptionCtrl" rows="4"
                                placeholder="Enter Description" autocomplete="off" required></textarea>
                        </mat-form-field>
                    </div>
                </div>

                <div fxLayout="row" style="position: absolute;bottom: 20px;width: 94%;">
                    <div fxFlex="50" style="text-align: start;">
                        <button class="primary-button" mat-button matStepperPrevious>Back</button>
                    </div>
                    <div fxFlex="50" style="text-align: end;">
                        <button [ngClass]="!detailsFormGroup.valid ? '' : 'primary-button'"
                            [disabled]="!detailsFormGroup.valid" mat-button matStepperNext>Next</button>
                    </div>
                </div>
            </form>
        </mat-step>

        <mat-step [stepControl]="equipmentFormGroup">
            <form [formGroup]="equipmentFormGroup" style="padding-top:10px">
                <ng-template matStepLabel>Select equipment</ng-template>

                <div style="text-align: center;">
                    <mat-form-field appearance="outline" style="width: 25%;font-size: 15px;margin-top: 13px;">
                        <mat-label>Select type</mat-label>
                        <mat-select formControlName="equipmentCtrl" disableOptionCentering
                            (selectionChange)="equipmentsTypeChange($event.value)">
                            <mat-option value='all'>All</mat-option>
                            <mat-option value='equipment'>By Equipment</mat-option>
                            <mat-option value='categorie'>By Equipment category</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div style="margin-top: 10px;">
                    <div class="centered" *ngIf="equipmentFormGroup.controls.equipmentCtrl.value == 'all'">
                        <h3 style="color:#00629b;">All equipment will be included in the schedule
                        </h3>
                    </div>

                    <div class="mat-elevation-z8" style="width:70%;margin: auto;height:52vh; overflow: auto;"
                        *ngIf="equipmentFormGroup.controls.equipmentCtrl.value == 'equipment'">
                        <mat-progress-bar *ngIf="isShowEquipmentsProgressBar" class="primary-color"
                            mode="indeterminate">
                        </mat-progress-bar>
                        <mat-toolbar class="container-toolbar ">
                            <mat-toolbar-row class="container-toolbar-row">
                                <mat-form-field appearance="outline" style="font-size: 15px;margin-top: 12px;width:35%">
                                    <mat-label>Search equipment</mat-label>
                                    <mat-icon matPrefix>search</mat-icon>
                                    <input autocomplete="off" matInput type="text" (keyup)="searchEquipment($event)"
                                        formControlName="equipmentSearchCtrl">
                                    <button *ngIf="equipmentFormGroup.controls.equipmentSearchCtrl.value" matSuffix
                                        mat-icon-button aria-label="Clear" (click)="clearEquipmentSerach()">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </mat-form-field>

                                <span class="example-spacer"></span>
                                <mat-paginator #paginator [pageSize]="paginatorLength"
                                    [pageSizeOptions]="[5, 10, 25, 50, 100]" (page)="handleEquipmentPaginator($event);"
                                    [length]="equipmentsLength">
                                </mat-paginator>
                            </mat-toolbar-row>
                        </mat-toolbar>

                        <table mat-table [dataSource]="equipmentDataSource" matSort
                            (matSortChange)="sortEquipmentfields($event)">
                            <!-- Checkbox Column -->
                            <ng-container matColumnDef="select">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-checkbox (change)="$event ? equipmentsMasterToggle() : null"
                                        [checked]="equipmentSelection.hasValue() && isEquipmentsAllSelected()"
                                        [indeterminate]="equipmentSelection.hasValue() && !isEquipmentsAllSelected()">
                                    </mat-checkbox>
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="toggeleEquipmetSelection($event, row)"
                                        [checked]="selectedEquipment(row)">
                                    </mat-checkbox>
                                </td>
                            </ng-container>

                            <!-- ID Column -->
                            <ng-container matColumnDef="EQUIPMENT_ID">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                                <td mat-cell *matCellDef="let element"> {{element.EQUIPMENT_ID}} </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="NAME">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                                <td mat-cell *matCellDef="let element"> {{element.NAME}} </td>
                            </ng-container>

                            <ng-container matColumnDef="CATEGORY_NAME">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Category Name </th>
                                <td mat-cell *matCellDef="let element"> {{element.CATEGORY_NAME}} </td>
                            </ng-container>

                            <!-- Description Column -->
                            <ng-container matColumnDef="DESCRIPTION">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                                <td mat-cell *matCellDef="let element"> {{element.DESCRIPTION}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="equipmentColumns;"></tr>
                            <tr mat-row *matRowDef="let row; columns: equipmentColumns;"
                                (click)="equipmentSelection.toggle(row)">
                            </tr>
                        </table>

                    </div>

                    <div class="mat-elevation-z8" style="width:70%;margin: auto;height:52vh; overflow: auto;"
                        *ngIf="equipmentFormGroup.controls.equipmentCtrl.value == 'categorie'">
                        <mat-progress-bar *ngIf="isShowCategoriesProgressBar" class="primary-color"
                            mode="indeterminate">
                        </mat-progress-bar>
                        <mat-toolbar class="container-toolbar ">
                            <mat-toolbar-row class="container-toolbar-row">
                                <mat-form-field appearance="outline" style="font-size: 15px;margin-top: 12px;width:35%">
                                    <mat-label>Search category</mat-label>
                                    <mat-icon matPrefix>search</mat-icon>
                                    <input autocomplete="off" matInput type="text" (keyup)="searchCategory($event)"
                                        formControlName="categorySearchCtrl">
                                    <button *ngIf="equipmentFormGroup.controls.categorySearchCtrl.value" matSuffix
                                        mat-icon-button aria-label="Clear" (click)="clearCategorySerach()">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </mat-form-field>
                                <span class="example-spacer"></span>
                                <mat-paginator #paginator [pageSize]="paginatorLength"
                                    [pageSizeOptions]="[5, 10, 25, 50, 100]" (page)="handleCategoriesPaginator($event);"
                                    [length]="categoriesLength">
                                </mat-paginator>
                            </mat-toolbar-row>
                        </mat-toolbar>

                        <table mat-table [dataSource]="categoriesDataSource" matSort
                            (matSortChange)="sortCategoriesfields($event)">
                            <!-- Checkbox Column -->
                            <ng-container matColumnDef="select">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-checkbox (change)="$event ? categoriesMasterToggle() : null"
                                        [checked]="categoriesSelection.hasValue() && isCategoriesAllSelected()"
                                        [indeterminate]="categoriesSelection.hasValue() && !isCategoriesAllSelected()">
                                    </mat-checkbox>
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="toggeleCategorySelection($event, row)"
                                        [checked]="selectedCategory(row)">
                                    </mat-checkbox>
                                </td>
                            </ng-container>

                            <!-- ID Column -->
                            <ng-container matColumnDef="CATEGORY_ID">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                                <td mat-cell *matCellDef="let element"> {{element.CATEGORY_ID}} </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="NAME">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                                <td mat-cell *matCellDef="let element"> {{element.NAME}} </td>
                            </ng-container>

                            <!-- Description Column -->
                            <ng-container matColumnDef="DESCRIPTION">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                                <td mat-cell *matCellDef="let element"> {{element.DESCRIPTION}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="categoryColumns;"></tr>
                            <tr mat-row *matRowDef="let row; columns: categoryColumns;"
                                (click)="categoriesSelection.toggle(row)">
                            </tr>
                        </table>

                    </div>
                </div>

                <mat-toolbar style="position:absolute;bottom:20px;height:40px;background:none;width: 96%;left:30px">
                    <button class="primary-button" mat-button matStepperPrevious>Back</button> &nbsp;&nbsp;
                    <button class="danger-button" mat-button (click)="stepper.reset()">Reset</button>
                    <span class="example-spacer"></span>
                    <button *ngIf="this.data?.mode == 1" [ngClass]="!equipmentFormGroup.valid ? '' : 'success-button'"
                        (click)="createAuditScheduleByType()" [disabled]="!equipmentFormGroup.valid" mat-button
                        matStepperNext>Create</button>

                    <button *ngIf="this.data?.mode == 2" [ngClass]="!equipmentFormGroup.valid ? '' : 'success-button'"
                        (click)="updateAuditScheduleByType()" [disabled]="!equipmentFormGroup.valid" mat-button
                        matStepperNext>Update</button>
                </mat-toolbar>
            </form>
        </mat-step>

    </mat-stepper>
</div>
<!--#endregion -->